@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

body{
  background: #000;
}
.App {
  text-align: center;
  width: 100%;
  min-width: 960px;
  min-height: 100vh;
  background: #000;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  /* padding-top: 140px; */
  padding-top: 126px;
  font-family: Poppins-SemiBold;
  box-sizing: border-box;
overflow-x: hidden;
}
.hvr-sweep-to-right:before{
  background: #fffce4;
}
.center{
  max-width: 1440px;
  min-width: 960px;
  margin: 0 auto;
  padding-top: 0px;
}
.logo{
  width: 160px;
  height: 40px;
  cursor: pointer;
  margin-right:5px ;
}
.header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
  padding-top: 40px;
  padding-bottom: 20px;
  position: fixed;
  top: 0;
  background: #000;
  z-index: 100;
}
.headerCenter{
  width: 100%;
  max-width: 1340px;
  min-width: 920px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding: 0 20px;
}
.mobileFP_textArea{
  width: 100Vw;
  min-height:300px ;
  position: absolute;
  top: 15vh;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobileFP_text1{
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  color: rgba(255, 252, 228, 1);
}
.mobileFP_text2{
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(255, 252, 228, 1);
  padding: 0 50px;
  margin-top: 12px;
  margin-bottom: 32px;
  font-family: Poppins-Regular;
}
.headerLeft{
  display: flex;
  align-items: center;
}
.headerRight{
  display: flex;
  align-items: center;
}
.headerBtn{
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 252, 228, 1);
  padding:24px 5px;
  margin-left: 1vw;
  cursor: pointer;
  position: relative;
}
.headerButton:hover{
  transform: scale(1.02);
  color: #000;
}
.helpYou_button2:hover,
.headerButton:hover,
.demoBtn:hover{
  transform: scale(1.02);
  color: #000;
}
.demoBtnActive{
  background:#fffce4 ;
  color: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 11px;
  z-index: -1;
  display: none;
}

.headerButton2:hover,
.whitePaperBtn:hover,
.helpYou_button1:hover
{
  transform: scale(1.05);
  opacity: 0.7;
}
.footer_link_item:hover
{
  /* transform: scale(1.05); */
  opacity: 0.7;
} 
.textMotion_cubeIn{
  height: 20px;
  transform-style:preserve-3d;
  animation: cubeActive 0.3s  linear ;
  animation-fill-mode: forwards;
}
.textMotion_cubeOut{
  height: 20px;
  transform-style:preserve-3d;
  animation: cubeOut 0.3s  linear ;
  animation-fill-mode: forwards;
}
.textMotion_cube_item_front{
  position: absolute;
  transform:translateZ(10px);
  line-height: 1;
}
.textMotion_cube_item_bottom{
  position: absolute;
  transform:rotateX(-90deg) translateZ(10px);
  line-height: 1;
  color: #119059;
}
@keyframes cubeActive {
  0% {
      -webkit-transform: rotateX(0deg) rotateY(0deg);
      transform:rotateX(0deg) rotateY(0deg);
  }
  100% {
      -webkit-transform: rotateX(90deg) rotateY(0deg);
      transform:rotateX(90deg) rotateY(0deg);
  }
}
@keyframes cubeOut {
  0% {
      -webkit-transform: rotateX(90deg) rotateY(0deg);
      transform:rotateX(90deg) rotateY(0deg);
  }
  100% {
      -webkit-transform: rotateX(0deg) rotateY(0deg);
      transform:rotateX(0deg) rotateY(0deg);
  }
}
/* .fpAmtion{
  animation-duration: 2s;
} */
.headerButton{
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 252, 228, 1);
  padding:16px 32px;
  border:1px solid rgba(255, 252, 228, 1) ;
  border-radius: 8px;
  flex-grow:0;
  flex-shrink:0;
  cursor: pointer;
  transition: all 0.4s;
  overflow: hidden;
}

.headerButton2{
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  padding:16px 32px;
  border:1px solid rgba(255, 252, 228, 1) ;
  border-radius: 8px;
  background: rgba(255, 252, 228, 1);
  margin-left: 40px;
  flex-grow:0;
  flex-shrink:0;
  cursor: pointer;
  transition: all 0.4s;
}

.extra{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}
.extraLink{
  margin-right: 25px;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.extraLink:last-child{
  margin-right: 0;
}
.extraLink:hover{
  border-bottom: 2px solid #fff;
}

.firstPageContent{
  width:100%;
  height: 100%;
  min-width: 960px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
}
.firstPage{
  width: 100%;
  height:100%;
}
.firstPage_bg{
  width: 100%;
  height: calc(100vh - 165px);
  min-width: 960px;
  position: relative;
}
.bodyImgMax1440{
  width: 100%;
  height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bodyImgMin1440{
  width: 100%;
  height: calc(100vh - 328px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}

.bgMinLeft{
  width: 20%;
  max-width: 380px;
  height: 100%;
  background: url("https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/bgMinLeft.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgMinRight{
  width: 20%;
  max-width: 380px;
  height: 100%;
  background: url("https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/bgMinRight.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgMaxLeft{
  width: 30%;
  max-width: 500px;
  height: 100%;
  background: url("https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/bgMaxLeft.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgMaxRight{
  width: 30%;
  max-width: 500px;
  height: 100%;
  background: url("https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/bgMaxRight.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgLeft img{
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1700px) {
  .bodyImgMax1440{
    display: none;
  }
  .bodyImgMin1440{
    display: flex;
  }
}




.firstPageArea{
  width: 100%;
  min-width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.firstPageText{
  font-size: 76px;
  line-height: 92px;
  font-weight: 700;
  color: rgba(255, 252, 228, 1);
  word-spacing:-2px
}
.forMarginRight{
  margin-right: 15px;
}
.firstPageText2{
  width: 80%;
  font-size: 20px;
  font-weight: 400;
  color: rgba(255, 252, 228, 1);
  max-width: 1100px;
  margin-top: 40px;
  font-family: Poppins-Regular;
}
.fp_bottom{
  width: 100%;
  overflow-x: hidden;
  display: flex;
  background-color: rgba(23, 24, 27, 1);
  position: relative;
  top: 5px;
}
.light{ 
  display: flex;
  -webkit-animation: 1500s chainSpeed linear infinite ;
  animation: 1500s chainSpeed linear infinite ;
}
@keyframes chainSpeed {
  0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }
  100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
  }
}
.lightItem{
  width: 339px;
  font-size: 14px;
  font-weight: 500;
  padding:5px 0 5px 80px;
  flex-grow:0;
  flex-shrink:0;
  background-color: rgba(23, 24, 27, 1);
}

.buttons{
  width: 100%;
  min-width: 960px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  position: relative;
  top: 0px;
  box-sizing: border-box;
}
.whitePaperBtn{
  padding: 24px 52px;
  border-radius: 12px;
  background: rgba(255, 252, 228, 1);
  color: #000;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.4s;
}
.demoBtn{
  width: 30%;
  padding: 22px 3%;
  border-radius: 12px;
  /* background: #000; */
  color: rgba(255, 252, 228, 1);
  font-size: 20px;
  font-weight: 700;
  margin-left: 80px;
  cursor: pointer;
  border: 1px solid rgba(255, 252, 228, 1);
  margin-left: 40px;
  transition: all 0.4s;
  position: relative;
  overflow: hidden;
}
.demoBtn:first-child{
  margin-left: 0;
}
.demoBtnVideo{
  padding: 24px;
  border-radius: 12px;
  background: #000;
  cursor: pointer;
  border: 1px solid rgba(255, 252, 228, 1);
  margin-left: 40px;
  display: flex;
  
}

.videoModal .ant-modal-content{
  padding: 0 !important;
  border-radius: 12px !important;
  background: #000 !important;
  border: 2px solid rgba(255, 252, 228, 0.29);
}
.ant-modal-mask{
  background: rgba(41, 42, 47, 0.6) !important;
  backdrop-filter: blur(6px);
}
.ant-modal-close{
  color: rgba(229, 223, 223 , 45%) !important;
}
.fp_text{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.fp_cubeIn{
  height: 88px;
  width: 750px;
  transform-style:preserve-3d;
  animation: fpActive 0.5s  linear 3s ;
  animation-fill-mode: forwards;
  text-align: center;
}
.fp_item_front{
  position: absolute;
  transform:translateZ(44px);
  line-height: 1;
  background: #000;
  width: 100%;
}
.fp_item_bottom{
  position: absolute;
  transform:rotateX(-90deg) translateZ(44px);
  line-height: 1;
  background: #000;
  width: 100%;
}

@keyframes fpActive {
  0% {
      -webkit-transform: rotateX(0deg);
      transform:rotateX(0deg);
  }
  100% {
      -webkit-transform: rotateX(90deg);
      transform:rotateX(90deg);
  }
}




.mobileIcon{
  width: 100%;
  position: relative;
}
.menuIcon{
  position: absolute;
  right: 20px;
  top: 18px;
  width: 24px;
  z-index: 4;
}


.step{
  width: 100%;
  display: flex;
  justify-content: center;
}
.step_body{
  width: 100%;
  max-width: 1300px;
  min-width: 960px;
  min-height: 1400px;
}
.step_list{
  margin: 180px 0 0 0;
  width: 100%;
}
.step_item{
  display: flex;
  align-items: center;
  gap:140px;
  min-height: 358px;
  margin-bottom: 160px;
}
.step_item2{
  display: flex;
  align-items: center;
  /* gap:140px; */
  min-height: 358px;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-bottom: 160px;
}
.step_item_text{
  width: 50%;
  min-height: 247px;
  max-width: 540px;
  text-align: right;
}
.step_item_img{
  width: 50%;
  max-width: 540px;
  min-height: 353px;
}
.step_item_img_pico{
  max-width: 460px;
  min-height: 460px;
}
.step_item_text_special{
  width: 50%;
  height: 247px;
  max-width: 540px;
  text-align: left;
}
.step_item_text_pico{
  width: 70%;
  min-height: 247px;
  max-width: 800px;
  text-align: right;
}
.step_item_text_special_pico{
  width: 70%;
  min-height: 460px;
  max-width: 800px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step_item_img img{
  width: 100%;
}
.step_item_text_title{
  font-size: 20px;
  font-weight: 700;
  color: rgba(17, 144, 89, 1);
}
.step_item_text_subTitle{
  font-size: 57px;
  font-weight: 700;
  color: rgba(255, 252, 228, 1);
  word-spacing:-2px; 
  letter-spacing: -2px;
}
.step_item_text_subTitle_pico{
  font-size: 56px;
  line-height: 60px;
  font-weight: 700;
  color: rgba(255, 252, 228, 1);
  word-spacing:-2px; 
  letter-spacing: -2px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.step_item_text_desc{
  font-size: 20px;
  font-weight: 500;
  margin-top: 12px;
  line-height: 28px;
  color: rgba(255, 252, 228, 1);
  font-family: Poppins-Regular
}
.mobileHeader{
  width:100%;
  height: 60px;
  position: fixed;
  top: 0;
  z-index: 100;
  background: #000;
  box-sizing: border-box;
}
.mobileLogo{
  height: 24px;
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 4;
}
.introduce{
  width: 100%;
  display: flex;
  justify-content: center;
}
.introduce_body{
  width: 100%;
  max-width: 1440px;
  min-width: 960px;
  min-height: 714px;
  padding: 0 50px;
  box-sizing: border-box;
  margin-bottom: 100px;
}
.introduce_list{
  width: 100%;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 16px;
}
.introduce_item{
  flex: 1 ;
  height: 100%;
  max-width: 436px;
  min-height: 688px;
  box-sizing: border-box;
  padding: 50px 30px;
  background:#17181B;
  text-align: left;
  display: inline-block;
}
.introduce_item_text_title{
  font-size: 48px;
  font-weight: 700;
  color:rgba(255, 252, 228, 1);
  letter-spacing: -2px;
}
@media screen and (max-width: 1450px) {
  .introduce_item{
    min-height:714px;
  }

}
@media screen and (max-width: 1365px) {
  .introduce_item{
    min-height:714px;
  }
  .introduce_item_text_title{
    font-size: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .introduce_item{
    min-height:727px;
  }
}
@media screen and (max-width: 1150px) {
  .introduce_item{
    min-height:745px;
  }
  .introduce_item_text_title{
    font-size: 35px;
  }
}
@media screen and (max-width: 1050px) {
  .introduce_item{
    min-height:759px;
  }
  .introduce_item_text_title{
    font-size: 28px;
  }
}


.introduce_item_text_subTitle{
  font-size: 18px;
  font-weight: 700;
  height: 45px;
  color:rgba(255, 252, 228, 1);
  margin-bottom: 80px;
  margin-top: 16px;
  padding-left: 5px;
}
.introduce_item_img img{
  width: 100%;
  height:210px ;
}
.introduce_item_text_desc{
  font-size: 18px;
  font-weight: 400;
  color:rgba(255, 252, 228, 1);
  margin-top: 20px;
  line-height: 26px;
  font-family: Poppins-Regular
}
.empower{
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(255, 252, 228, 1);
  
}
.empower_body{
  width: 100%;
  max-width: 1440px;
  min-width: 960px;
  min-height: 1920px;
  padding: 160px 70px 40px 70px;
  box-sizing: border-box;
}
.empower_title{
  font-size: 88px;
  font-weight: 700;
  color: #000;
  margin-bottom: 30px;
  text-align: left;
  word-spacing:-3px; 
  letter-spacing:-2px;
  line-height: 107px;
}
.empower_level{
  display: flex;
  justify-content: space-between;
}
.empower_list{
  color: #000;
  padding: 0 40px;
  box-sizing: border-box;
}
.empower_item{
  width: 40%;
  min-height: 346px;
  max-width: 540px;
  text-align: left;
  margin-bottom: 120px;
}
.empower_item_img img{
  height: 120px;
  margin-bottom: 20px;
}
.empower_item_text_title{
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.empower_item_text_desc{
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #000;
  margin-top: 12px;
  font-family: Poppins-Regular
}
.empower_item_text_desc_link{
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  width: fit-content;
  .empower_item_text_desc_linkText{
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-right: 5px;
  }
  img{
    left: 0;
  }
}
.empower_linkText_title{
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #000;
  font-family: Poppins-Regular;
  margin-right: 5px;
}

.helpYou{
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 1);
  
}
.helpYou_body{
  width: 100%;
  max-width: 1440px;
  min-width: 960px;
  min-height: 901px;
  padding: 160px 0;
  box-sizing: border-box;
}
.helpYou_title{
  width: 100%;
  font-size: 130px;
  line-height: 138px;
  font-weight: 600;
  color: rgba(255, 252, 228, 1);
  margin-bottom: 60px;
  text-align: center;
  word-spacing: 2px;
  letter-spacing:-2px;
}
.helpYou_img{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.helpYou_img img{
  width: 590px;
  height: 168px;
}
.helpYou_buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:60px;
}
.helpYou_button1{
  width: 300px;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  background: rgba(255, 252, 228, 1);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 24px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s;
}
.helpYou_button2{
  width: 300px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 252, 228, 1);
  border: 1px solid rgba(255, 252, 228, 1);
  background: rgba(0, 0, 0, 1);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 24px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s;
  overflow: hidden;
}
.footer{
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  
}
.footer_body{
  width: 100%;
  max-width: 1340px;
  min-width: 960px;
  padding: 100px 60px 230px 60px;
  box-sizing: border-box;
}
.footer_level{
  display: flex;
  justify-content: space-between;
}
.footer_left{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
}
.footer_img{
  width: 210px;
  cursor: pointer;
}
.footer_reserved{
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 252, 228, 0.6);
  margin-top: 20px;
  font-family: Poppins-Regular
}
.footer_right{
  display: flex;
  justify-content: flex-end;
  gap: 90px;
}
.footer_resources{
  text-align: left;
  min-width: 220px;
}
.footer_resourcesText{
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: rgba(17, 144, 89, 1);
  margin-bottom: 10px;
  text-align: left;
}
.footer_contactUs{
  text-align: left;
  min-width: 220px;
}
.footer_resourcesText2{
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: rgba(249, 166, 225, 1);
  margin-bottom: 10px;
}
.footer_link_item{
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: rgba(255, 252, 228, 1);
  cursor: pointer;
  margin-top: 24px;
  transition: all 0.4s;
  text-align: left;
}

/* .exchange{
  border-right: 2px solid transparent;
  animation:  blink-caret .75s step-end infinite;
  line-height: 1;
}
@keyframes blink-caret {
  from, to { box-shadow: 1px 0 0 0 transparent; }
  50% { box-shadow: 1px 0 0 0; }
} */
@media screen and (max-width: 960px) {
  .App{
    padding-top:0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    background: #000;
  }
  .footer_body{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .firstPage{
    box-sizing: border-box;
  }
  .firstPage_bg{
    box-sizing: border-box;
  }
  .headerCenter{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100vh;
    background: #000;
    position: fixed;
    z-index: 3;
    top: 60px;
    left: 0;
    box-sizing: border-box;
    padding: 0;
    padding-top: 16px;
  }
  .header{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }
  .headerLeft{
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
    width: 100%;
    box-sizing: border-box;
  }
  .headerBtn{
    margin-left: 0;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  .headerRight{
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding:0 44px;
    box-sizing: border-box;
  }
  
}



.docDropDown{
  position: absolute;
  top: 68px;
  left: -9px;
  width: 240px;
  height: 144px;
  z-index: 101;
  border-radius: 12px;
  border: 1px solid #adadaa;
  background: #000;
  text-align: left;
  display: none;
  .docDropDownText{
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
  .docDropDownText:hover{
    background: #17181B;
    border-radius: 12px 12px 0 0px;
    .headerBtn{
      color: #119059 !important;
    }
   
  }
  .docDropDownText:last-child:hover{
    background: #17181B;
    border-radius: 0 0 12px 12px;
  }
}
.animate__animated.animate__slideInUp {
  --animate-duration: 0.3s;
}
@keyframes slideInUp{
  0%{
    transform: translate3d(0, 5%, 0);
  }
  100%{
    transform: translate3d(0, 0, 0);
  }
}
.zkTitle{
  font-size: 80px;
  font-weight: 500;
  line-height: 88px;
  text-align: center;
  color: #FFFCE4;
  font-family:Poppins-SemiBold;
}
.videoPage{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @keyframes myBreath {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.15);
    }
    100%{
        transform: scale(1);
    }
  }
  .palyVideo{
    width: 31px;
    height: 31px;
    animation: myBreath 2s linear infinite;  
  }
  .videoOut{
    margin-top: 100px;
    width: 900px;
    box-sizing: border-box;
    cursor: pointer;
    border:6px solid rgba(255, 255, 255, 0.11);
  }
  .videoOut:hover{
    opacity: 0.8;
  }
  .videoContent{
    width: 100%;
    height: 100%;
  }
}

.readDocLink{
  font-family:Poppins-SemiBold;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
  color: rgba(243, 194, 8, 1);
  margin-top: 24px;
  flex: 1;
  width: fit-content;
  cursor: pointer;
}
.imgBody{
  width: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.readDocLinkIcon{
  left:0;
  position: relative;
}
.readDocLinkText{
  cursor: pointer;
}
.readDocLink:hover,
.empower_item_text_desc_link:hover{
  .readDocLinkIcon{
    animation: imgMove 0.5s linear;
    animation-fill-mode: forwards;
  }
}
@keyframes imgMove {
  0%{
    left:-100%;
  }
  100%{
    left:0;
  }
}

.buttonLine{
  width: 100%;
  height: 1px;
}
.textActive{
  animation: changeColor2 0.5s linear;
  animation-fill-mode: forwards;
}
.btnReadyAction{
  animation: changeHeight 0.5s linear;
  animation-fill-mode: forwards;
}
@keyframes changeColor2 {
  0%{
    color:  rgba(255, 252, 228, 0.6);
  }
  100%{
    color: #000;
  }
}
@keyframes changeHeight {
  0%{
    height:  calc(100vh - 128px);
    /* opacity: 0.7; */
  }
  100%{
    height: 68px;
    /* opacity: 1; */
  }
}


.menuBtnState{
  position: relative;
  background: #000;
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.menuOut{
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuOutFixed{
  position: fixed;
  background: #000;
  top: 128px;
  z-index: 5;
  padding-bottom: 20px;
  padding-top: 20px;
  height: 200px;
  animation: menuOutFixedActive 0.3s ease-out;
  animation-fill-mode: forwards;


}
@keyframes menuOutFixedActive {
  0%{
    /* top:200px; */
    height: 200px;
  }
  100%{
   /* top: 128px; */
    height: 88px;
  }
}
.menuBtn{
  width: 800px;
  height: 68px;
  border: 1px solid rgba(255, 252, 228, 1);
  border-radius: 12px;
  padding: 2px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
}

.btnLeftText{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
  font-size: 32px;
  font-weight: 600;
  line-height: 64px;
  text-align: center;
  color: rgba(255, 252, 228, 0.6);
  cursor: pointer;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnLeftText:hover ,.btnRightText:hover{
  opacity: 0.7;
}
.textActive:hover{
  opacity: 1;
}
.btnRightText{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 98;
  font-size: 32px;
  font-weight: 600;
  line-height: 64px;
  text-align: center;
  color: rgba(255, 252, 228, 0.6);
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.animate__animated.animate__slideInRight,
.animate__animated.animate__slideInLeft {
  --animate-duration: 0.5s;
  --animation-timing-function: cubic-bezier(0.42,0,1,1);
}
.menuOutItem{
  height: 100%;
  flex: 1;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: rgba(255, 252, 228, 0.6);
  background: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.menuOutActive{
  background: rgba(255, 252, 228, 1) !important;
  color: rgba(0, 0, 0, 1);
  opacity: 1 !important;
}
.menuBtnStateAction{
  animation: buttonStateActive 0.3s linear;
  animation-fill-mode: forwards;
}

.menuBtnAction{
  animation: buttonActive 2s linear;
  animation-delay:1s;
  animation-fill-mode: forwards;
}
.moveBtnOutAction{
  display: block;
  animation: moveBtnOutActionCur 2s linear;
  animation-delay:1s;
  animation-fill-mode: forwards;
}

@keyframes buttonActive {
  0%{
    width: 100vw;
    height: 100vh;
    border-radius:0;
  }
  100%{
    width: 800px;
    height: 74px;
    border-radius: 12px;
    display: none;
  }
}
@keyframes moveBtnOutActionCur {
  0%{
   display: flex;
  }
  100%{
   
    display: none;
  }
}

@keyframes buttonStateActive {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}



.picoGlue{
  width:100%;
  border: 1px solid rgba(255, 255, 255, 0.16);
  font-size: 75px;
  font-weight: 600;
  line-height: 88px;
  letter-spacing: -2%;
  padding: 40px 16px 30px 30px;
  text-align: left;
  box-sizing: border-box;
  color: rgba(255, 252, 228, 1);
  border-right: none;
}
.picoDec{
  /* width: 70%; */
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 20px 35px 22px 35px;
  border-top: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  box-sizing: border-box;
  font-family: Poppins-Regular;
  color: rgba(255, 252, 228, 1);
  border-top: none;
}
.picoImg{
  /* width: 100%; */
  /* height: 729px; */
  line-height: 1;
  border-left: none;
  img{
    width: 100%;
  }
}
.pico_item{
  display: flex;
  align-items: flex-start;
  min-height: 745px;
  
}
.pico_item_left{
  width: 63%;
}
.changeTextColor{
  animation: changeColor 30s linear;
  animation-iteration-count: infinite;
}
@keyframes changeColor {
  0%{
    color: #edabde;
  }
  15%{
    color: #edabde;
  }
  16%{
    color: rgba(242, 255, 94, 1);
  }
  31%{
    color: rgba(242, 255, 94, 1);
  }
  32%{
    color: rgba(164, 137, 243, 1);
  }
  47%{
    color: rgba(164, 137, 243, 1);
  }
  48%{
    color: rgba(243, 194, 8, 1);
  }
  63%{
    color: rgba(243, 194, 8, 1);
  }
  64%{
    color: rgba(17, 144, 89, 1);
  }
  79%{
    color: rgba(17, 144, 89, 1);
  }
  80%{
    color: rgba(249, 110, 71, 1);
  }
  99%{
    color: rgba(249, 110, 71, 1);
  }
  100%{
    color: #edabde;
  }
  
}
.step_item_text_title_pico{
  width: 100%;
  text-align: left;
}
.step_item_text_title_pico_sp{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.spDot{
  width: 50px;
  height: 8px;
  border-radius: 11px;
  background: #119059;
}
.stepLine{
  width: 100%;
  height: 1px;
  margin: 160px 0 120px 0;
  position: relative;
}
.stepLineInner{
  width: 100vw;
  height: 1px;
  background: rgba(23, 24, 27, 1);
  position: absolute;
  left:calc(50% - 50vw);
}
@media screen and (min-width: 1901px) {
  /* .headerBtn {
    margin-left: 0.5vw;
    padding: 24px 0.5vw;
  }
  .log{
    margin-right: 0.5vw;
  } */
}

@media screen and (max-width: 1900px) {

  .firstPageText {
    font-size: 64px;
    line-height: 80px;
  }
  .firstPageText2{
    width: 80%;
    font-size: 18px;
  }
}
@media screen and (max-width: 1440px) {
  /* .headerBtn {
     margin-left: 1vw;
     padding: 24px 1vw;
  } */
  /* .headerButton{
    padding: 16px 1vw;
  } */
  /* .headerButton2 {
     margin-left: 1vw;
  } */
}
@media screen and (max-width: 1355px) {
  .firstPageText {
    font-size: 54px;
    line-height: 70px;
  }
  .firstPageText2{
    width: 75%;
  }
  .fp_cubeIn{
    width: 630px;
    height: 72px;
  }
  .fp_item_front{
    transform:translateZ(36px);
  }
  .fp_item_bottom{
    transform:rotateX(-90deg) translateZ(36px);
  }

}

@media screen and (max-width: 1170px) {
  .firstPageText {
    font-size: 44px;
    line-height: 60px;
  }
  .firstPageText2{
    width: 70%;
  }
  .fp_cubeIn{
    width: 570px;
    height: 65px;
  }
  .fp_item_front{
    transform:translateZ(32px);
  }
  .fp_item_bottom{
    transform:rotateX(-90deg) translateZ(32px);
  }
  .picoGlue{
    font-size: 70px;
    line-height: 80px;
  }
  .zkTitle{
    font-size: 70px;
    line-height: 78px;
  }
}

@media screen and (max-width: 1050px) {
  .firstPageText {
      font-size: 34px;
      line-height: 50px;
  }
  .firstPageText2{
    width: 60%;
  }
  .fp_cubeIn{
    width: 520px;
    height: 60px;
  }
  .fp_item_front{
    transform:translateZ(30px);
  }
  .fp_item_bottom{
    transform:rotateX(-90deg) translateZ(30px);
  }
  .picoGlue{
    font-size: 65px;
    line-height: 75px;
  }
}
.step_item_pico{
  display: flex;
  align-items: center;
  gap:40px;
  min-height: 468px;
  margin-bottom: 100px;
}
.step_item2_pico{
  display: flex;
  align-items: center;
  min-height: 468px;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-bottom: 120px;
}
.pageView{
  min-height: 2500px;
}

.teams{
  width: 100%;
  height: 100vh;
  background: #000;
  background-image: url(../src/images/teamsBg.svg);
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.27);
  border-right: none;
  border-left: none;
  .teamsTitle{
    font-size: 88px;
    font-weight: 600;
    line-height: 96px;
    letter-spacing: -3px;
    text-align: center;
    color:rgba(255, 252, 228, 1);
    margin-bottom: 120px;
  }
  .teamsList{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    .teamsListItem{
      width: 500px;
      height:140px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-right: none;
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000;
      /* flex-grow:0;
      flex-shrink:0;  */
      .teamsListItemTextColor{
        display: none;
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
    .teamsListItem:last-child{
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    .teamsListItemText{
      background: #000;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .teamsListItem:hover{
      .teamsListItemTextColor{
        display: block;
        height: 100%;
        animation: imgAction 0.3s linear;
        animation-fill-mode: forwards;
      }
      .teamsListItemTextWhite{
        display: none;
        height: 100%;
      }
    }
  }
}

@keyframes imgAction{
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(1.1);
  }
}

.imgMotion_cubeIn{
  height:  80px;
  width: 100%;
  position: relative;
  transform-style:preserve-3d;
  animation: cubeImgActive 12s  linear ;
  animation-fill-mode: forwards;
  animation-delay:0;
  animation-iteration-count: infinite;
  /* animation-play-state:paused ; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.teamsList:hover{
  .imgMotion_cubeIn{
    animation-play-state:paused;
  }
}
.imgMotion_item_1{
  position: absolute;
  transform:translateZ(40px);
}
.imgMotion_item_2{
  position: absolute;
  transform:rotateX(-90deg) translateZ(40px);
	line-height: 1;
}
.imgMotion_item_3{
  position: absolute;
  transform:rotateX(90deg) translateZ(40px);
  line-height: 1;
}
.imgMotion_item_4{
  position: absolute;
  transform:  translateZ(-40px) rotateX(180deg);
  line-height: 1;
}
@keyframes cubeImgActive {
  0% {
    transform:rotateX(0deg) rotateY(0deg)
  }
  1.5%{
    transform:rotateX(90deg) rotateY(0deg);
  }
  25%{
    transform:rotateX(90deg) rotateY(0deg);
  }
  26.5%{
    transform:rotateX(180deg) rotateY(0deg);
  }
  50%{
    transform:rotateX(180deg) rotateY(0deg);
  }
  51.5%{
    transform:rotateX(270deg) rotateY(0deg);
  }
  75%{
    transform:rotateX(270deg) rotateY(0deg);
  }
  76.5% {
    transform:rotateX(360deg) rotateY(0deg);
  }
  100% {
    transform:rotateX(360deg) rotateY(0deg);
  }
}

.mobileList{
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
}
.mobileListItem{
  width: 32%;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-right: none;
}
.teamsImg{
  width: 100%;
  height: 100%;
}
.bottomLine{
 border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.mobileListItem:last-child{
 border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.mobileBtn{
  width: 100%;
  padding: 20px 16px ;
  background: #000;
  box-sizing: border-box;
 
}
.mobileBtnFixed{
  position: fixed;
  top: 40px;
  z-index: 100;
}
.mobileBtnBody{
  width: 100%;
  border:1px solid rgba(255, 252, 228, 1) ;
  border-radius: 12px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.mobileBtnItem{
  width: 50%;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(255, 252, 228, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileBtnItemActive{
  background: rgba(255, 252, 228, 1);
  color: #000;
}















@media screen and (max-width: 960px) {
  .App{
    min-width: 100%;
    max-width: 100%;
    padding-top: 0;
  }
  .firstPage_bg{
    width: 100%;
    height: auto;
    /* height:calc(100vh - 30px); */
    min-width: 100%;
    max-width: 100%;
  }
  .bodyImg{
    max-width: 100%;
    height: 100%;
    margin-top: 60px;
  }
  .headerBtn{
    font-size: 16px;
    display:flex ;
    justify-content: center;
    padding:20px 24px;
  }
  .headerButton{
    width: 100%;
    margin-left: 0;
    font-size: 16px;
    padding-top: 13px;
    line-height: 24px;
    padding-bottom: 13px;
    margin-top: 24px;
    box-sizing: border-box;
  }
  .headerButton2{
    width: 100%;
    margin-left: 0;
    font-size: 16px;
    line-height: 24px;
    padding-top: 13px;
    padding-bottom: 13px;
    margin-top: 20px;
    box-sizing: border-box;
  }
  .extra{
    flex-direction:column;
    margin-top: 0;
    align-items: flex-end;
  }
  .extraLink{
    margin-right: 0;
  }

  .buttons {
    width: 100vw;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  .whitePaperBtn{
    width: calc(100vw - 40px);
    margin-left: 0;
    font-size: 16px;
    padding: 12px 0;
  }
  .demoBtn{
    width: calc(100vw - 40px);
    margin-left: 0;
    margin-top: 20px;
    font-size: 16px;
    padding: 12px 0;
  }
  .demoBtnVideo{
    width: calc(100vw - 40px);
    margin-left: 0;
    margin-top: 20px;
    font-size: 16px;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .firstPageContent{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    justify-content: flex-end;
  }
  .step_list{
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .step_body{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 1680px;
  }
  .step_item{
    flex-direction: column;
    gap: 40px;
    min-height: auto;
  }
  .step_item2{
    flex-direction: column;
    gap: 40px;
  }

  .step_item_text{
    text-align: left;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .step_item_text_special{
    text-align: left;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .step_item_text_title{
    font-size: 20px;
  }
  .step_item_text_subTitle{
    font-size: 48px;
    line-height: 56px;
  }
  .introduce_item_img img{
    height: auto;
  }
  .empower_item_img img{
    height: auto;
  }

  .introduce_body{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 1820px;
    padding: 0 20px;
  }
  .introduce_list{
    flex-direction: column;
    width: 100%;
  }
  .introduce_item{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: auto;
    padding: 60px 20px;
    background: #202227;
  }
  .introduce_item_text_title{
    font-size: 40px;
    line-height: 48px;
  }
  .empower_body{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 1845px;
    padding: 160px 20px 100px 20px;
  }
  .empower_title{
    font-size: 48px;
    line-height: 56px;
    word-spacing: 2px;
    letter-spacing: -2px;
    margin-bottom: 80px;
  }
  .empower_level{
    display: unset;
  }
  .empower_list{
    padding: 0;
  }
  .empower_item{
    width: 100%;
    max-width: 100%;
    margin-bottom: 80px;
  }
  .helpYou_body{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 160px 20px 160px 20px;
  }
  .helpYou_title{
    font-size: 40px;
    margin-bottom: 20px;
    line-height: 48px;
  }
  .helpYou_img{
    padding: 0 20px;
    box-sizing: border-box;
  }
  .helpYou_img img{
    width: 100%;
    height: auto;
    min-height: 118px;
    margin-bottom: 20px;
  }
  .helpYou_buttons{
    flex-direction: column;
    gap: 20px;
  }
  .helpYou_button1{
    width: 100%;
    padding: 12px 0;
    font-size: 18px;
  }
  .helpYou_button2{
    width: 100%;
    padding: 12px 0;
    font-size: 18px;
  }
  .footer_body{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 80px 20px 80px 44px;
  }
  .footer_level{
    flex-direction: column;
  }
  .footer_left{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  .footer_img{
    width: 134px;
    cursor: pointer;
  }
  .footer_reserved{
    font-size: 12px;
    line-height: 16px;
    margin-top: 0;
    width: 100%;
    text-align: left;
    opacity: 0.6;
    margin-top: 40px;
  }
  .footer_right{
    flex-direction: column;
    gap: 40px;
  }
  .footer_resourcesText{
    font-size: 20px;
    line-height: 28px;
    margin: 12px 0px;
  }
  .footer_resourcesText2{
    font-size: 20px;
    line-height: 28px;
    margin: 12px 0px;
  }
  .step_item_text_desc{
    font-size: 18px;
    margin-top: 20px;
    line-height: 26px;
  }
  .introduce_item_text_desc{
    font-size: 18px;
    line-height: 26px;
  }
  .empower_item_text_desc{
    font-size: 18px;
    line-height: 26px;
  }
  .empower_item_text_title{
    font-size: 20px;
    line-height: 28px;
  }


  .introduce_item_text_subTitle{
    font-size: 18px;
    line-height: 26px;
    height: auto;
    margin-bottom: 60px;
  }
  .footer_link_item{
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    
  }

  .headerButton:hover,
  .helpYou_button2:hover,
  .demoBtn:hover{
    transform: scale(1);
    opacity: 1;
   
  }

  .headerButton2:hover,
  .whitePaperBtn:hover,
  .helpYou_button1:hover
  {
    transform: scale(1);
    opacity: 1;
  }
  .footer_link_item:hover
  {
    /* transform: scale(1); */
    opacity: 0.8;
  } 
  .lightItem{
    padding:5px 0 5px 0px;
  }
  .fp_bottom{
    position: relative;
    top: 120px;
  }
  .menuOut{
    width: 100% ;
    padding:10px 16px;
    box-sizing: border-box;
    align-items: center;
    
  }

  .menuBtn{
    width: 100% !important;
    height: 50px !important;
    font-size: 16px;
    border: 1px solid rgba(255, 252, 228, 1) !important;
    border-radius: 12px !important;
  }
  .menuOutItem{
    font-size: 16px !important;
    border-radius: 10px !important;
  }
  .menuPosition{
    margin-bottom: 180px !important;
  }
  .zkTitle{
    
    font-size: 36px;
    line-height: 44px;
    text-align: left;
    padding-left: 16px;
  }
  .pageView{
    margin-top: 40px !important;
    width: 100%;
  }

  .pico_item{
    flex-direction: column;
    border: none;
  }
  .pico_item_left{
    width: 100%;
  }
  .menuBtnState{
    height: 50px;
  }
  .picoGlue{
    font-size: 36px;
    line-height: 44px;
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-top: 0;
    border: none;
  }
  .stepLine{
    margin-bottom: 0;
  }
  .picoDec{
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
  .menuOutFixed{
    top:60px;
    z-index: 1000;
  }
  .step_item2_pico{
    flex-direction: column-reverse;
    width: 100%;
  }
  .step_item_text_special_pico{
    width: 100%;
    height: auto;
  }
  .step_item_img_pico{
    width: 100%;
    min-height: auto;
  }
  .step_item_img_pico_img{
    width: 100%;
  }
  .step_item_text_title_pico,.step_item_text_special_pico{
    text-align: left;
  }
  .step_item_text_subTitle_pico{
    font-size: 36px;
    line-height: 44px;
  }
  .step_item_pico{
    flex-direction: column-reverse;
    width: 100%;
  }
  .step_item_text_pico{
    width: 100%;
    text-align: left;
  }
  .step_item_text_title_pico_sp{
    justify-content: left;
  }
  .teams{
    height: auto;
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .teamsTitle{
    font-size: 40px !important;
    line-height: 48px !important;
  }
  .footer_resourcesText{
    text-align: left;
  }
  .footer_link_item{
    text-align: left;
  }
  .videoOut{
    width: calc(100vw - 32px) !important;
  }
  .videoPage{
    padding: 0 16px;
    box-sizing: border-box;
  }
  .step_item_img{
    width: 100%;
  }
  .readDocLink{
    width: 100%;
    justify-content: flex-start;
  }
  .btnLeftText{
    font-size: 16px !important; 
    left:calc(25% - 49px) !important;
    line-height: 48px !important;
  }
  .btnRightText{
    font-size: 16px !important; 
    right: 0;
    left:calc(75% - 60px) !important;
    line-height: 48px !important;
  }







}